import React from "react";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAmbulance,
  faArrowLeft,
  faArrowRight,
  faEdit,
  faFloppyDisk,
  faPlus,
  faSearch,
  faTrash,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Form, Nav, Row } from "react-bootstrap";
import ContratoTabLegacy from "./ContratoTabLegacy";
import ContratoTabFinanceiro from "./ContratoTabFinanceiro";
import ContratoTabAtendimento from "./ContratoTabAtendimento";
import ContratoTabTelefonia from "./ContratoTabTelefonia";
import { CONTRATO_SITUACOES_VARIANTES } from "datatypes/ContratoTypes";
import ContratoTabNF from "./ContratoTabNF";
import { DateTime } from "luxon";

const tabNames = [
  {
    nome: "Dados",
    render: (count, iframeRef) => (
      <ContratoTabLegacy count={count} iframeRef={iframeRef} url="form/contratos/tab_dados_enderecos.php" />
    ),
  },
  {
    nome: "Serviços",
    render: (count, iframeRef) => (
      <ContratoTabLegacy count={count} iframeRef={iframeRef} url="form/contratos/tab_servicos.php" />
    ),
  },
  {
    nome: "Acessos",
    render: (count, iframeRef) => (
      <ContratoTabLegacy count={count} iframeRef={iframeRef} url="form/contratos/tab_acessos.php" />
    ),
  },
  {
    nome: "Financeiro",
    render: (count) => <ContratoTabFinanceiro count={count} />,
  },
  {
    nome: "NF",
    render: (count, iframeRef) => <ContratoTabNF count={count} iframeRef={iframeRef} />,
  },
  {
    nome: "Atendimentos",
    render: (count, iframeRef) => <ContratoTabAtendimento count={count} />,
  },
  {
    nome: "OTT",
    render: (count, iframeRef) => (
      <ContratoTabLegacy count={count} iframeRef={iframeRef} url="form/contratos/tab_ott.php" />
    ),
  },
  {
    nome: "Telefonia",
    render: (count, iframeRef) => <ContratoTabTelefonia count={count} />,
  },
  {
    nome: "Produtos",
    render: (count, iframeRef) => (
      <ContratoTabLegacy count={count} iframeRef={iframeRef} url="form/contratos/tab_produtos.php" />
    ),
  },
  {
    nome: "Documentos",
    render: (count, iframeRef) => (
      <ContratoTabLegacy count={count} iframeRef={iframeRef} url="form/contratos/tab_documentos.php" />
    ),
  },
  {
    nome: "Observações",
    render: (count, iframeRef) => (
      <ContratoTabLegacy count={count} iframeRef={iframeRef} url="form/contratos/tab_observacoes.php" />
    ),
  },
];

export default function Contrato() {
  const navigate = useNavigate();
  const { contrato_id } = useParams();
  const { socket } = React.useContext(IoContext);
  const [tabAtiva, setTabAtiva] = React.useState("Dados");
  const [loading, setLoading] = React.useState(false);
  const [contrato, setContrato] = React.useState(null);
  const [numeroContrato, setNumeroContrato] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [modo, setModo] = React.useState("view");
  const iframeRef = React.useRef(null);

  React.useEffect(() => {
    setLoading(true);
    setContrato(null);
    setNumeroContrato("");

    if (contrato_id === "add") {
      setModo("new");
    }

    socket.emit(
      "Contrato.findOne",
      {
        attributes: ["id", "numero_contrato", "situacao"],
        where: {
          id: contrato_id,
        },
        withPessoa: {
          attributes: ["nome", "dataSpc", "listaNegra"],
        },
      },
      (error, resp) => {
        setLoading(false);

        if (error) {
          toast.error("" + error);
          return console.error("Contrato.findOne ERROR:", error);
        }

        if (resp) {
          setContrato(resp);
          setNumeroContrato(resp?.numero_contrato);
        }
      }
    );
  }, [contrato_id, socket]);

  React.useEffect(() => {
    iframeRef?.current?.contentWindow?.lh?.form?.setMode(modo);
  }, [modo]);

  return (
    <div className="w-100 h-100 ps-2 pe-2">
      <Card className="w-100 h-100">
        <Card.Header className={`${CONTRATO_SITUACOES_VARIANTES[contrato?.situacao] || "bg-default"} d-flex`}>
          <Row className="w-100">
            <Col sm={1}>
              <Form.Control
                size="sm"
                value={numeroContrato}
                onChange={(e) => setNumeroContrato(e.target.value)}
                onKeyDown={(e) => {
                  if (e.which === 13) {
                    navegarParaContrato({
                      socket,
                      navigate,
                      setLoading,
                      where: {
                        numero_contrato: numeroContrato,
                      },
                    });
                  }
                }}
              />
            </Col>

            <Col
              sm={8}
              style={{
                color: "white",
                verticalAlign: "middle",
              }}
            >
              {contrato?.Pessoa?.nome}
              {contrato?.Pessoa?.dataSpc && (
                <b className="ms-2">
                  [SPC: {DateTime.fromISO(contrato?.Pessoa?.dataSpc).toLocaleString(DateTime.DATE_SHORT)}]
                </b>
              )}
              {contrato?.Pessoa?.listaNegra ? <b className="ms-2 bg-danger">[LISTA NEGRA]</b> : false}
            </Col>

            <Col
              sm={3}
              className="p-0"
              style={{
                textAlign: "right",
              }}
            >
              <span className={`${modo === "view" ? "" : "d-none"}`}>
                <Button
                  size="sm"
                  variant="success"
                  className="float-right me-2"
                  disabled={loading}
                  title="Novo"
                  onClick={() => {
                    try {
                      navigate("../add");
                    } catch (error) {
                      console.log("Novo Error:", error);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>

                <Button
                  size="sm"
                  variant="info"
                  className={`float-right me-2`}
                  disabled={loading}
                  title="Editar"
                  onClick={() => {
                    try {
                      setModo("edit");
                    } catch (error) {
                      console.log("Novo Error:", error);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>

                <Button
                  size="sm"
                  variant="danger"
                  className="float-right me-2"
                  disabled={loading}
                  title="Apagar"
                  onClick={() => {
                    try {
                      iframeRef?.current?.contentWindow?.lh?.menubar?.apagar(null, () => {
                        setModo("view");
                        navegarAnterior({
                          socket,
                          navigate,
                          setLoading,
                          contrato_id,
                        });
                      });
                    } catch (error) {
                      console.log("Apagar Error:", error);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </span>

              <span className={`${modo === "new" || modo === "edit" ? "" : "d-none"}`}>
                <Button
                  size="sm"
                  variant="success"
                  className="float-right me-2"
                  disabled={loading}
                  title="Salvar"
                  onClick={() => {
                    try {
                      if (!iframeRef?.current?.contentWindow?.lh?.forms?.active_form?.validarForm()) {
                        return;
                      }

                      iframeRef?.current?.contentWindow?.lh?.menubar?.salvar();
                      setModo("view");
                    } catch (error) {
                      console.log("Salvar Error:", error);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faFloppyDisk} />
                </Button>

                <Button
                  size="sm"
                  variant="warning"
                  className="float-right me-2"
                  disabled={loading}
                  title="Cancelar"
                  onClick={() => {
                    try {
                      iframeRef?.current?.contentWindow?.lh?.menubar?.cancelar(null, () => {
                        setModo("view");
                        if (contrato_id === "add") {
                          navigate(`..`);
                        }
                      });
                    } catch (error) {
                      console.log("Salvar Error:", error);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
              </span>

              <span className="me-2 text-light">|</span>

              <Button
                size="sm"
                variant="secondary"
                className="float-right me-2"
                title="Interface Antiga/Legacy"
                disabled={loading}
                onClick={() => {
                  navigate("/lgc/contratos");
                }}
              >
                <FontAwesomeIcon icon={faAmbulance} />
              </Button>

              <span className={`${modo === "view" ? "" : "d-none"}`}>
                <Button
                  size="sm"
                  variant="light"
                  className="float-right me-2"
                  title="Anterior"
                  disabled={loading}
                  onClick={async () => {
                    navegarAnterior({
                      socket,
                      navigate,
                      setLoading,
                      contrato_id,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>

                <Button
                  size="sm"
                  variant="light"
                  className="float-right me-2"
                  title="Próximo"
                  disabled={loading}
                  onClick={async () => {
                    navegarProximo({
                      socket,
                      navigate,
                      setLoading,
                      contrato_id,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </span>

              <Button
                size="sm"
                variant="info"
                className="float-right me-2"
                disabled={loading}
                onClick={() => {
                  navigate("..");
                }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Col>
          </Row>
        </Card.Header>

        <Tab.Container defaultActiveKey="Dados">
          <Nav
            onSelect={(tab) => {
              setTabAtiva(tab);
              setCount(count + 1);
            }}
            variant="tabs"
          >
            {tabNames.map((tab) => (
              <Nav.Item key={tab.nome}>
                <Nav.Link eventKey={tab.nome} active={tabAtiva === tab.nome} disabled={loading}>
                  {tab.nome}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <Tab.Content className="h-100">
            {tabNames.map((tab) => (
              <Tab.Pane eventKey={tab.nome} className="h-100" key={tab.nome}>
                {tab.nome === tabAtiva && tab.render(count, iframeRef)}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Card>
    </div>
  );
}

async function navegarAnterior({ socket, navigate, setLoading, contrato_id }) {
  const id = await navegarParaContrato({
    socket,
    navigate,
    setLoading,
    where: {
      id_lt: contrato_id,
    },
    order: [["id", "desc"]],
  });

  if (!id) {
    // Se nao achou o anterior, vai para o ultimo
    navegarParaContrato({
      socket,
      navigate,
      setLoading,
      where: {},
      order: [["id", "desc"]],
    });
  }
}

async function navegarProximo({ socket, navigate, setLoading, contrato_id }) {
  const id = await navegarParaContrato({
    socket,
    navigate,
    setLoading,
    where: {
      id_gt: contrato_id,
    },
  });

  if (!id) {
    // Se nao achou o proximo, vai para o primeiro
    navegarParaContrato({
      socket,
      navigate,
      setLoading,
      where: {},
    });
  }
}

async function navegarParaContrato({ socket, navigate, setLoading, where, order = [] }) {
  return new Promise((resolve) => {
    setLoading(true);
    socket.emit(
      "Contrato.findOne",
      {
        attributes: ["id"],
        where,
        order,
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error("Proximo Error:", error);
          return resolve(false);
        }

        if (resp?.id) {
          navigate(`../${resp.id}`);
          resolve(resp.id);
        }

        resolve(false);
      }
    );
  });
}
