import React from "react";
import { toast } from "react-toastify";
import IoContext from "contextos/IoContext";
import FormularioListagem from "componentes/FormularioListagem";
import { DateTime } from "luxon";

export default function ServidoresSemBackup() {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [pagina, setPagina] = React.useState(1);
  const [texto, setTexto] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [servidores, setServidores] = React.useState([]);

  React.useEffect(() => {
    socket.emit(
      "ServidorBackup.findAll",
      {
        attributes: ["ServidorId"],
        where: {
          createdAt_gt: new Date().toISOString().split("T")[0],
        },
      },
      (error, resp) => {
        let where = {
          id_notIn: resp.map((i) => i.ServidorId),
          tipo_in: ["MIKROTIK", "UBIQUIT", "OUTROS"],
        };
        if (texto) where.nome_like = `%${texto}%`;

        socket.emit(
          "Servidor.findAndCountAll",
          {
            attributes: ["id", "nome", "ip", "dataUltimoBackup"],
            where,
            limit: 10,
            offset: 10 * (pagina - 1),
            // withBackups: {
            //     attributes: [],
            //     where: {
            //         id: null,
            //         createdAt_gt: moment().format('YYYY-MM-DD')
            //     },
            //     required: false
            // }
          },
          (error, resp) => {
            setLoading(false);
            if (error) {
              toast.error(error);
            } else {
              setTotal(resp.count);
              setServidores(resp.rows);
            }
          }
        );
      }
    );
  }, [texto, pagina, socket]);

  return (
    <FormularioListagem
      titulo={`Servidores Sem Backup`}
      itens={servidores || []}
      qtdItens={total}
      pagina={pagina}
      loading={loading}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={["Id", "Nome", "Ip", "Ultimo Backup"]}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Nome":
            return item.nome;
          case "Ip":
            return item.ip;
          case "Ultimo Backup":
            return DateTime.fromISO(item.dataUltimoBackup).toLocaleString(DateTime.DATE_SHORT);
          default:
            return titulo;
        }
      }}
    />
  );
}
