import React from "react";
import ApexCharts from "react-apexcharts";
import GraficoCancelamentoPorFilial from "./GraficoCancelamentoPorFilial.js";

import IoContext from "contextos/IoContext.js";
import { Card, Container, Col, Row, Button, Offcanvas, Form } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { toast } from "react-toastify";
import SelectMes from "componentes/SelectMes.js";
import SelectFiliais from "paginas/cadastros/administrativo/filiais/SelectFiliais.js";

export default function PowerBICancelamentos() {
  const [show, setShow] = React.useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [FilialId, setFilialId] = React.useState(0);
  const [mes, setMes] = React.useState(new Date().getMonth() + 1);
  const [ano, setAno] = React.useState(new Date().getFullYear());
  const { socket } = React.useContext(IoContext);
  const [chartData, setChartData] = React.useState({});
  const [filialSelecionada, setFilialSelecionada] = React.useState(null);
  const totalDias = new Date(ano, mes, 0).getDate();
  const listaDias = Array.from({ length: totalDias }, (val, idx) => idx + 1);
  const dini = new Date(ano, mes - 1, 1).toISOString().split("T")[0];
  const dend = new Date(ano, mes - 1, totalDias).toISOString().split("T")[0];
  const [historico, setHistorico] = React.useState([]);

  React.useEffect(() => {
    setChartData([]);
    setHistorico([]);

    socket.emit(
      "HistoricoServicoContratado.findAll",
      {
        attributes: ["id"],
        where: {
          createdAt_between: [dini, dend],
          situacao: "CANCELADO",
        },
        withServicoContratado: {
          required: true,
          attributes: ["situacao", "valor"],
          withPlano: {
            attributes: ["nome"],
          },
          withContrato: {
            attributes: ["numero_contrato"],
            withPessoa: {
              attributes: ["nome", "telefone1"],
            },
            withFilial: {
              attributes: ["id", "nome"],
            },
          },
        },
      },
      (error, resp) => {
        if (error) {
          return toast.error(typeof error === "string" ? error : error?.message);
        }
        // nao entendi pq contabilizar por dia, mas vou deixar aqui, pode vc precisar.

        // const totalPorDia = listaDias.reduce((acc, value) => ({ ...acc, [value]: 0 }), {});
        // (resp || []).forEach((hist) => {
        //   if (!FilialId || FilialId == hist?.ServicoContratado?.Contrato?.Filial?.id) {
        //     const [, , histDataDia] = hist.createdAt.split("T")[0].split("-");
        //     totalPorDia[histDataDia]++;
        //   }
        // }, {});
        // listaDias.forEach((dia) => {
        //   const formattedDia = dia < 10 ? `0${dia}` : `${dia}`;
        //   if (typeof totalPorDia[formattedDia] !== "number" || isNaN(totalPorDia[formattedDia])) {
        //     //Lidar com problema de NAN
        //     totalPorDia[formattedDia] = 0;
        //   }
        // });
        // console.log("chartData atualizado:", totalPorDia);
        // setChartData(totalPorDia);
        console.log("HistoricoServicoContratado.findAll", resp);
        setHistorico(resp || []);
      }
    );
  }, [socket, ano, mes, FilialId, dini, dend]);

  const seriesData = Object.values(chartData);
  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h1 className="float-start">Relatório de Cancelamentos Geral</h1>
          <Button variant="secondary" className="float-end mt-2" onClick={handleShow}>
            <FaFilter />
          </Button>

          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Filtros:</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form.Text>Período de Cancelamento:</Form.Text>
              <Row>
                <Col>
                  <SelectMes value={mes} onChange={(e) => setMes(e.target.value)} />
                </Col>
                <Col>
                  <Form.Control type="number" value={ano} onChange={(e) => setAno(+e.target.value)} />
                </Col>
              </Row>
              <Form.Text>Filial:</Form.Text>
              <SelectFiliais value={FilialId} required={true} onChange={(e) => setFilialId(e.target.value)} />
            </Offcanvas.Body>
          </Offcanvas>
        </Card.Header>
      </Card>
      <Row>
        <Col className="mt-1">
          <Card className="shadow rounded">
            <Card.Body>
              {/* Mover isso para um arquivo separado */}
              <ApexCharts
                options={{
                  title: {
                    text: `Cancelamentos x Mês`,
                    style: {
                      fontWeight: "normal",
                      fontSize: "18px",
                    },
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "20%",
                    },
                  },
                  dataLabels: {
                    enabled: true,
                  },
                  stroke: {
                    show: true,
                    width: 13,
                    colors: ["transparent"],
                  },
                  xaxis: {
                    categories: listaDias,
                  },
                  yaxis: {
                    max: 6,
                    title: {
                      text: `Vendas`,
                      style: {
                        fontWeight: "normal",
                        fontSize: "15px",
                      },
                    },
                  },
                }}
                series={[
                  {
                    name: "Cancelamentos por Mês",
                    data: seriesData,
                  },
                ]}
                type="bar"
                height={353}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col className="text-center mt-1">
          <Row className="mb-2">
            <Col>
              <Card style={{ height: "8rem" }}>
                <Card.Title className="mt-2">
                  Cancelamentos {dini.split("-").reverse().join("/")} / {dend.split("-").reverse().join("/")}
                </Card.Title>
                <Card.Body style={{ fontSize: "50px", marginTop: "-20px" }}>{historico.length}</Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Card style={{ height: "8rem" }}>
                <Card.Title className="mt-2">
                  Perda de Receita {mes}/{ano}
                </Card.Title>
                <Card.Body style={{ fontSize: "40px", marginTop: "-10px" }}>{}</Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ height: "8rem" }}>
                <Card.Title className="mt-2">
                  Ticket Médio {mes}/{ano}
                </Card.Title>
                <Card.Body style={{ fontSize: "40px", marginTop: "-10px" }}>{}</Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <GraficoCancelamentoPorFilial historico={historico} />
      </Row>
    </Container>
  );
}
