import React from "react";
import { Routes, Route } from "react-router-dom";
import PowerBICancelamentos from "./cancelamentos/PowerBICancelamentos";

export default function SistemaIndex() {
  return (
    <Routes>
      <Route path={`/cancelamentos`} element={<PowerBICancelamentos />} />
    </Routes>
  );
}
