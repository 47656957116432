import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Container from "react-bootstrap/Container";
import SelectMoeda from "./SelectMoeda";
import SelectCarteiraCobranca from "./SelectCarteiraCobranca";
import { toast } from "react-toastify";
import CampoDoc1 from "componentes/CampoDoc1";
import CampoAgenciaBancaria from "componentes/CampoAgenciaBancaria";
import CampoContaBancaria from "componentes/CampoContaBancaria";
import CampoProcurarEndereco from "../../administrativo/enderecos/CampoProcurarEndereco";
import BotaoApagar from "componentes/BotaoApagar";
import CabecalhoCadastro from "componentes/CabecalhoCadastro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSearch } from "@fortawesome/free-solid-svg-icons";
import IoContext from "contextos/IoContext";
import { useNavigate, useParams } from "react-router";
import AuthContext from "contextos/AuthContext";

export default function ContaBancaria() {
  const { usuario } = React.useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const id = parseInt(params.id);
  const [oid, setOid] = React.useState("");
  const [MoedaId, setMoedaId] = React.useState(0);
  const [CarteiraCobrancaId, setCarteiraCobrancaId] = React.useState(0);
  const [titular, setTitular] = React.useState("");
  const [cnpj, setCnpj] = React.useState("");
  const [banco, setBanco] = React.useState("");
  const [numero, setNumero] = React.useState("");
  const [juros, setJuros] = React.useState("");
  const [multa, setMulta] = React.useState("");
  const [variacao_carteira, setVariacaoCarteira] = React.useState("");
  const [local_de_pagamento, setLocalDePagamento] = React.useState("");
  const [observacao1, setObservacao1] = React.useState("");
  const [observacao2, setObservacao2] = React.useState("");
  const [observacao3, setObservacao3] = React.useState("");
  const [cedente, setCedente] = React.useState("");
  const [agencia, setAgencia] = React.useState("");
  const [conta, setConta] = React.useState("");
  const [cnab, setCnab] = React.useState(400);
  const [token, setToken] = React.useState("");
  const [cliente_id, setClienteId] = React.useState("");
  const [cliente_secret, setClienteSecret] = React.useState("");
  const [endereco, setEndereco] = React.useState("");
  const [EnderecoId, setEnderecoId] = React.useState(0);
  const [dias_baixa_banco, setDiasBaixaBanco] = React.useState(25);
  const [CertificadoPix, setCertificadoPix] = React.useState("");
  const [AmbientePix, setAmbientePix] = React.useState("");
  const [chavePix, setChavePix] = React.useState("");
  const [dataConsultaPix, setDataConsultaPix] = React.useState("");
  const [horaConsultaPix, setHoraConsultaPix] = React.useState("");
  const [dataConsultaBoletos, setDataConsultaBoletos] = React.useState("");
  const [horaConsultaBoletos, setHoraConsultaBoletos] = React.useState("");
  const [applicationKey, setApplicationKey] = React.useState("");
  const [senhaCertificado, setSenhaCertificado] = React.useState("");
  const [alterarSenhaCertificado, setAlterarSenhaCertificado] = React.useState(false);
  const [alterarCertificadoPix, setAlterarCertificadoPix] = React.useState(false);
  const [tipoTarifaPix, setTipoTarifaPix] = React.useState(1);
  const [tarifaPix, setTarifaPix] = React.useState(0);
  const [tarifaRegistroBoleto, setTarifaRegistroBoleto] = React.useState(0);
  const [tarifaLiquidacaoBoleto, setTarifaLiquidacaoBoleto] = React.useState(0);
  const [diasGerarRemessa, setDiasGerarRemessa] = React.useState(0);
  const [temWebhookAtivo, setTemWebhookAtivo] = React.useState(false);
  const [desativarGeracaoAutomaticaCarnes, setDesativarGeracaoAutomaticaCarnes] = React.useState(false);
  const fileRef = React.useRef(null);
  let tipoConta = getTipoCarteiraCobranca(banco);

  React.useEffect(() => {
    setLoading(true);
    socket.emit(
      "ContaBancaria.findOne",
      {
        where: { id },
        withEndereco: {},
        withCarteiraCobranca: {
          attributes: ["id", "banco"],
        },
      },
      (error, resp) => {
        setLoading(false);

        if (error) {
          toast.error("" + error);
        } else {
          if (!resp) return;
          let cb = resp || {};

          setOid(cb.oid);
          setMoedaId(cb.MoedaId);
          setCarteiraCobrancaId(cb.CarteiraCobrancaId);
          setTitular(cb.titular);
          setCnpj(cb.cnpj);
          setNumero(cb.numero);
          setJuros(cb.juros);
          setMulta(cb.multa);
          setVariacaoCarteira(cb.variacao_carteira);
          setLocalDePagamento(cb.local_de_pagamento);
          setObservacao1(cb.observacao1);
          setObservacao2(cb.observacao2);
          setObservacao3(cb.observacao3);
          setCedente(cb.cedente);
          setAgencia(cb.agencia);
          setConta(cb.conta);
          setCnab(cb.cnab);
          setToken(cb.token);
          setClienteId(cb.cliente_id);
          setClienteSecret(cb.cliente_secret);
          setEnderecoId(cb.EnderecoId);
          setEndereco(cb.Endereco);
          setDiasBaixaBanco(cb.dias_baixa_banco);
          setBanco(cb.CarteiraCobranca ? cb.CarteiraCobranca.banco : "");
          setCertificadoPix(cb.CertificadoPix);
          setAmbientePix(cb.AmbientePix);
          setApplicationKey(cb.applicationKey);
          setSenhaCertificado(cb.senhaCertificado);
          setChavePix(cb.chavePix);
          setTipoTarifaPix(cb.tipoTarifaPix);
          setTarifaPix(cb.tarifaPix);
          setTarifaRegistroBoleto(cb.tarifaRegistroBoleto);
          setTarifaLiquidacaoBoleto(cb.tarifaLiquidacaoBoleto);
          setDiasGerarRemessa(cb.diasGerarRemessa);
          setTemWebhookAtivo(cb.temWebhookAtivo);
          setDataConsultaPix(cb.dataConsultaPix?.split("T")[0] || "");
          setHoraConsultaPix(cb.dataConsultaPix?.split("T")[1]?.split(".")[0] || "");
          setDataConsultaBoletos(cb.dataConsultaBoletosPagos?.split("T")[0] || "");
          setHoraConsultaBoletos(cb.dataConsultaBoletosPagos?.split("T")[1]?.split(".")[0] || "");
          setDesativarGeracaoAutomaticaCarnes(cb.desativarGeracaoAutomaticaCarnes);
        }
      }
    );
  }, [id, socket]);

  return (
    <Container fluid className="pt-3">
      <Card>
        <input
          type="file"
          className="d-none"
          ref={fileRef}
          onChange={(e) => {
            var reader = new FileReader();
            reader.onload = function () {
              setCertificadoPix(reader.result.toString().split("base64,")[1]);
              setAlterarCertificadoPix(true);
            };
            reader.readAsDataURL(e.target.files[0]);
          }}
        />

        <CabecalhoCadastro
          id={id}
          disabled={loading}
          titulo="Conta Bancária"
          salvar={(e) => {
            e.preventDefault();
            if (!CarteiraCobrancaId) return toast.info("Selecione a Carteira de Cobrança");

            console.log(
              "Consulta Pix:",
              dataConsultaPix && horaConsultaBoletos
                ? `${dataConsultaPix}T${horaConsultaPix.split(":").slice(0, 2).join(":")}:00.000Z`
                : undefined
            );

            setLoading(true);
            socket.emit(
              "ContaBancaria.salvar",
              {
                id,
                CarteiraCobrancaId,
                MoedaId,
                EnderecoId,
                numero,
                titular,
                cnpj,
                cedente,
                agencia,
                conta,
                variacao_carteira,
                token,
                cliente_id,
                cliente_secret,
                AmbientePix,
                cnab,
                multa,
                juros,
                dias_baixa_banco,
                CertificadoPix,
                senhaCertificado,
                local_de_pagamento,
                observacao1,
                observacao2,
                observacao3,
                chavePix,
                applicationKey,
                tipoTarifaPix,
                diasGerarRemessa,
                tarifaPix,
                tarifaRegistroBoleto,
                tarifaLiquidacaoBoleto,
                temWebhookAtivo,
                dataConsultaPix:
                  dataConsultaPix && horaConsultaBoletos
                    ? `${dataConsultaPix}T${horaConsultaPix.split(":").slice(0, 2).join(":")}:00.000Z`
                    : undefined,
                dataConsultaBoletosPagos:
                  dataConsultaBoletos && horaConsultaBoletos
                    ? `${dataConsultaBoletos}T${horaConsultaBoletos.split(":").slice(0, 2).join(":")}:00.000Z`
                    : undefined,
                alterarSenhaCertificado,
                alterarCertificadoPix,
                desativarGeracaoAutomaticaCarnes,
              },
              (error, _resp) => {
                setLoading(false);
                if (error) {
                  toast.error(error);
                } else {
                  toast.success("Conta Bancária salva com Sucesso !");
                  navigate(-1);
                }
              }
            );
          }}
        />

        <Card.Body>
          <Row>
            <Col sm={1}>
              <Form.Group>
                <Form.Label>Id</Form.Label>
                <Form.Control value={isNaN(id) ? "" : id} readOnly />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Oid</Form.Label>
                <Form.Control value={oid || ""} readOnly />
              </Form.Group>
            </Col>

            <Col sm={5}>
              <Form.Group>
                <Form.Label>Carteira de Cobrança</Form.Label>
                <SelectCarteiraCobranca
                  disabled={loading}
                  value={CarteiraCobrancaId}
                  onChange={(e) => {
                    const slc = e.target;
                    const opt = slc.options[slc.selectedIndex];
                    setCarteiraCobrancaId(e.target.value);
                    setBanco(opt.getAttribute("data-banco"));
                  }}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Moeda</Form.Label>
                <SelectMoeda disabled={loading} value={MoedaId} onChange={(e) => setMoedaId(e.target.value)} />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Titular</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={titular}
                  maxLength={100}
                  onChange={(e) => setTitular(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <CampoDoc1 disabled={loading} value={cnpj} onChange={setCnpj} />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Opcões</Form.Label>
                <Form.Check
                  type="switch"
                  disabled={loading}
                  label="Geração Automática de Carnês ?"
                  checked={!desativarGeracaoAutomaticaCarnes}
                  onChange={() => {
                    setDesativarGeracaoAutomaticaCarnes(!desativarGeracaoAutomaticaCarnes);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          {tipoConta === "BANCO" ? (
            <React.Fragment>
              <Form.Group>
                <Form.Label>
                  <h4>Dados Bancários</h4>
                </Form.Label>
              </Form.Group>

              <Row>
                <Col sm={2}>
                  <Form.Group>
                    <Form.Label>Convênio / Cedente</Form.Label>
                    <Form.Control
                      disabled={loading}
                      value={cedente || ""}
                      maxLength={12}
                      onChange={(e) => setCedente(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col sm={2}>
                  <Form.Group>
                    <Form.Label>Agência</Form.Label>
                    <CampoAgenciaBancaria disabled={loading} value={agencia || ""} onChange={setAgencia} />
                  </Form.Group>
                </Col>

                <Col sm={2}>
                  <Form.Group>
                    <Form.Label>Conta</Form.Label>
                    <CampoContaBancaria disabled={loading} value={conta || ""} onChange={setConta} />
                  </Form.Group>
                </Col>

                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>CNAB</Form.Label>
                    <Form.Control
                      as="select"
                      disabled={loading}
                      value={cnab || 400}
                      onChange={(e) => setCnab(+e.target.value)}
                    >
                      <option value="240">240</option>
                      <option value="400">400</option>
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>Variação da Carteira</Form.Label>
                    <Form.Control
                      disabled={loading}
                      value={variacao_carteira}
                      onChange={(e) => setVariacaoCarteira(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            false
          )}

          {tipoConta === "GERENCIANET" ? (
            <React.Fragment>
              <Form.Group>
                <div className="text-center">
                  <h4>
                    Crie sua Conta clicando aqui:{" "}
                    <a href="https://gerencianet.com.br/parceiro/altsoft" target="gerencianet">
                      GerenciaNet
                    </a>
                  </h4>
                </div>
              </Form.Group>
            </React.Fragment>
          ) : (
            false
          )}

          {tipoConta === "BOLETOFACIL" || tipoConta === "JUNO" ? (
            <React.Fragment>
              <Form.Group>
                <Form.Label>
                  <h4>Dados de Integração com a API</h4>
                </Form.Label>
              </Form.Group>

              <Form.Group>
                <div className="text-center">
                  <h4>
                    Crie sua Conta clicando aqui:{" "}
                    <a href="https://app.juno.com.br/#/onboarding/132182:fc77de" target="juno">
                      JUNO
                    </a>
                  </h4>
                </div>
              </Form.Group>

              <Row>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Token de Integração</Form.Label>
                    <Form.Control
                      disabled={loading}
                      value={token}
                      maxLength={64}
                      onChange={(e) => setToken(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            false
          )}

          <React.Fragment>
            <Form.Group>
              <Form.Label>
                <h4>Dados de Integração com a API - Pix</h4>
              </Form.Label>
            </Form.Group>

            <Row>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Cliente Id</Form.Label>
                  <Form.Control
                    disabled={loading}
                    value={cliente_id || ""}
                    maxLength={255}
                    onChange={(e) => setClienteId(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Cliente Secret</Form.Label>
                  <Form.Control
                    disabled={loading}
                    value={cliente_secret || ""}
                    maxLength={512}
                    onChange={(e) => setClienteSecret(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Chave da Aplicação</Form.Label>
                  <Form.Control
                    disabled={loading}
                    value={applicationKey || ""}
                    maxLength={255}
                    onChange={(e) => setApplicationKey(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Opcões</Form.Label>
                  <Form.Check
                    type="switch"
                    disabled={loading}
                    label="Webhook Ativo ?"
                    checked={temWebhookAtivo}
                    onChange={() => {
                      setTemWebhookAtivo(!temWebhookAtivo);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Certificado</Form.Label>
                  <InputGroup>
                    <Form.Control disabled={loading} value={CertificadoPix || ""} readOnly />

                    <Button
                      variant="info"
                      className={`${usuario?.admin ? "" : "d-none"}`}
                      disabled={loading}
                      onClick={() => {
                        socket.emit("ContaBancaria.getCertificado", { id }, (error, resp) => {
                          if (error) return toast.error(String(error));

                          // Decode base64 string to binary
                          let binaryString = window.atob(resp);
                          let binaryLen = binaryString.length;
                          let bytes = new Uint8Array(binaryLen);
                          for (let i = 0; i < binaryLen; i++) {
                            let ascii = binaryString.charCodeAt(i);
                            bytes[i] = ascii;
                          }

                          // Create a Blob from the binary string
                          let blob = new Blob([bytes], { type: "application/octet-stream" });

                          // Create an Object URL from the Blob
                          let url = window.URL.createObjectURL(blob);

                          // Create a link element and programmatically click it to start the download
                          let link = document.createElement("a");
                          link.href = url;
                          link.download = `Certificado-${id}.pfx`;
                          link.click();

                          // Clean up by revoking the Object URL
                          window.URL.revokeObjectURL(url);
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>

                    <Button
                      disabled={loading}
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Senha do Certificado</Form.Label>
                  <Form.Control
                    disabled={loading}
                    value={senhaCertificado || ""}
                    onChange={(e) => {
                      setSenhaCertificado(e.target.value);
                      setAlterarSenhaCertificado(true);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Ambiente</Form.Label>
                  <Form.Control
                    as="select"
                    disabled={loading}
                    value={AmbientePix || ""}
                    onChange={(e) => setAmbientePix(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="p">Produção</option>
                    <option value="h">Homologação</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Chave Pix</Form.Label>
                  <Form.Control
                    disabled={loading}
                    value={chavePix || ""}
                    onChange={(e) => setChavePix(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Data da Consulta por Pagamento Pix</Form.Label>
                  <Form.Control
                    type="date"
                    disabled={loading}
                    value={dataConsultaPix || ""}
                    onChange={(e) => setDataConsultaPix(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Hora da Consulta por Pagamento Pix</Form.Label>
                  <Form.Control
                    type="time"
                    disabled={loading}
                    value={horaConsultaPix || ""}
                    onChange={(e) => setHoraConsultaPix(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Data da Consulta por Pagamento via Boleto</Form.Label>
                  <Form.Control
                    type="date"
                    disabled={loading}
                    value={dataConsultaBoletos || ""}
                    onChange={(e) => setDataConsultaBoletos(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Hora da Consulta por Pagamento via Boleto</Form.Label>
                  <Form.Control
                    type="time"
                    disabled={loading}
                    value={horaConsultaBoletos || ""}
                    onChange={(e) => setHoraConsultaBoletos(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </React.Fragment>

          <Form.Group>
            <Form.Label>
              <h4>Informações adicionais</h4>
            </Form.Label>
          </Form.Group>

          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Multa por Atraso (%)</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  max={99}
                  step={1}
                  disabled={loading}
                  value={multa || 0}
                  onChange={(e) => setMulta(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Juros ao Mês (%)</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  max={99}
                  step={1}
                  disabled={loading}
                  value={juros || 0}
                  onChange={(e) => setJuros(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Dias para Baixa no Banco</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  max={99}
                  step={1}
                  disabled={loading}
                  value={dias_baixa_banco || 0}
                  onChange={(e) => setDiasBaixaBanco(+e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Dias para Gerar Remessa</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  max={120}
                  step={1}
                  disabled={loading}
                  value={diasGerarRemessa || 0}
                  onChange={(e) => setDiasGerarRemessa(+e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Tipo da Tarifa Pix</Form.Label>
                <div>
                  <Form.Check
                    inline
                    checked={+tipoTarifaPix === 1}
                    type="radio"
                    name="tipoTarifaPix"
                    label="Percentual"
                    onChange={(e) => {
                      setTipoTarifaPix(1);
                    }}
                  />

                  <Form.Check
                    inline
                    checked={+tipoTarifaPix === 2}
                    type="radio"
                    name="tipoTarifaPix"
                    label="Valor Fixo"
                    onChange={(e) => {
                      setTipoTarifaPix(2);
                    }}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Tarifa Pix ({+tipoTarifaPix === 1 ? "%" : "R$"})</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  max={99}
                  step={0.01}
                  disabled={loading}
                  value={tarifaPix || 0}
                  onChange={(e) => setTarifaPix(+e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Tarifa Registro do Boleto (R$)</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  max={99}
                  step={0.01}
                  disabled={loading}
                  value={tarifaRegistroBoleto || 0}
                  onChange={(e) => setTarifaRegistroBoleto(+e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Tarifa Liquidação do Boleto (R$)</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  max={99}
                  step={0.01}
                  disabled={loading}
                  value={tarifaLiquidacaoBoleto || 0}
                  onChange={(e) => setTarifaLiquidacaoBoleto(+e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Endereço</Form.Label>
                <CampoProcurarEndereco
                  disabled={loading}
                  value={endereco}
                  onSelect={(o) => {
                    setEnderecoId(o ? o.id : null);
                    setEndereco(o);
                  }}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Número</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={numero || 0}
                  maxLength={64}
                  onChange={(e) => setNumero(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Local de Pagamento</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={local_de_pagamento || ""}
                  maxLength={64}
                  onChange={(e) => setLocalDePagamento(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Observação 1</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={observacao1 || ""}
                  maxLength={100}
                  onChange={(e) => setObservacao1(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Observação 2</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={observacao2 || ""}
                  maxLength={100}
                  onChange={(e) => setObservacao2(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Observação 3</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={observacao3 || ""}
                  maxLength={100}
                  onChange={(e) => setObservacao3(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer>
          {isNaN(id) ? (
            false
          ) : (
            <React.Fragment>
              <BotaoApagar
                className="me-2"
                disabled={loading}
                apagar={(e) => {
                  setLoading(true);
                  socket.emit("ContaBancaria.apagar", { id }, (error, resp) => {
                    setLoading(false);
                    if (error) {
                      toast.error(error);
                    } else {
                      toast.success("Conta Bancária Apagada com Sucesso !");
                      navigate(-1);
                    }
                  });
                }}
              />

              <Button
                className="d-none"
                onClick={() => {
                  navigate("historico");
                }}
              >
                Histórico
              </Button>
            </React.Fragment>
          )}
        </Card.Footer>
      </Card>
    </Container>
  );
}

const getTipoCarteiraCobranca = function (banco) {
  switch (banco) {
    case "JUNO":
      return "JUNO";
    case "GERENCIANET":
      return "GERENCIANET";
    case "BOLETO FACIL":
      return "BOLETOFACIL";
    case "LOJA":
      return "LOJA";
    default:
      return banco ? "BANCO" : "";
  }
};
