import React from "react";
import { Routes, Route } from "react-router-dom";
import CampSoft from "./campsoft/CampSoft";
import Fidelimax from "./fidelimax/Fidelimax";
import Cobli from "./cobli/Cobli";
import Traccar from "./traccar/Traccar";
import IntegracaoGoogleMaps from "./google_maps/IntegracaoGoogleMaps";
import Playhub from "./playhub/Playhub";
import PabxMegatelecom from "./PabxMegatelecom/PabxMegatelecom";
import Owen from "./owen/Owen";
import Alofone from "./alofone/Alofone";
import PortabilidadeCelular from "./portabilidadedecelular/PortabilidadeDeCelular";
import SpcBrasil from "./SpcBrasil/SpcBrasil";
import EiTV from "./eitv/EiTV";
import Leveduca from "./leveduca/Leveduca";
import WatchBrasil from "./watchbrazil/WatchBrasil";
import XtreamUI from "./xtreamui/XtreamUI";
import Megazap from "./megazap/Megazap";
import ItTV from "./ittv/ItTV";
import TcIRR from "./tcirr/TcIRR";
import IntegracaoCobrancaTerceirizada from "./cobranca_terceirizada/CobrancaTerceirizada";

export default function IntegracoesIndex() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/campsoft" element={<CampSoft />} />
        <Route path="/fidelimax" element={<Fidelimax />} />
        <Route path="/cobli" element={<Cobli />} />
        <Route path="/traccar" element={<Traccar />} />
        <Route path="/googlemaps" element={<IntegracaoGoogleMaps />} />
        <Route path="/playhub" element={<Playhub />} />
        <Route path="/pabx_megatelecom" element={<PabxMegatelecom />} />
        <Route path="/owen" element={<Owen />} />
        <Route path="/alofone" element={<Alofone />} />
        <Route path="/portabilidadecelular" element={<PortabilidadeCelular />} />
        <Route path="/spcbrasil" element={<SpcBrasil />} />
        <Route path="/eitv" element={<EiTV />} />
        <Route path="/leveduca" element={<Leveduca />} />
        <Route path="/watchbrasil" element={<WatchBrasil />} />
        <Route path="/xtream-ui" element={<XtreamUI />} />
        <Route path="/megazap" element={<Megazap />} />
        <Route path="/ittv" element={<ItTV />} />
        <Route path="/tcirr" element={<TcIRR />} />
        <Route path="/cobranca_terceirizada" element={<IntegracaoCobrancaTerceirizada />} />
      </Routes>
    </React.Fragment>
  );
}
