import React from "react";
import ApexCharts from "react-apexcharts";
import { Card, Col } from "react-bootstrap";

export default function GraficoCancelamentoPorFilial({ historico }) {
  const [dadosGrafico, setDadosGrafico] = React.useState({});

  React.useEffect(() => {
    const dados = {};

    historico.forEach((item) => {
      const filial = item.ServicoContratado?.Contrato?.Filial?.nome;
      dados[filial] = (dados[filial] || 0) + 1;
    });

    setDadosGrafico(dados);
    console.log("Dados Graficos", dados, Object.keys(dados), Object.values(dados));
  }, [historico]);

  return (
    <Col className="mt-1">
      <Card className="shadow rounded">
        <ApexCharts
          options={{
            title: {
              text: `Cancelamentos x Filial`,
              style: {
                fontWeight: "normal",
                fontSize: "18px",
              },
            },
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: "20%",
              },
            },
            dataLabels: {
              enabled: true,
            },
            xaxis: {
              categories: Object.keys(dadosGrafico),
            },
            yaxis: {
              max: 6,
              title: {
                text: "Filiais",
                style: {
                  fontWeight: "normal",
                  fontSize: "15px",
                },
              },
            },
          }}
          series={[
            {
              name: "Cancelamento por Filial",
              data: Object.values(dadosGrafico),
            },
          ]}
          type="bar"
          height={383}
        />
      </Card>
    </Col>
  );
}
